import React from 'react';
import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';

import BookingCalendarMultiSpec from 'components/booking/BookingCalendarMultiSpec';
import BookingSectionTitle from 'components/booking/bookingSectionTitle';
import ExternalLink from 'components/booking/externalLink';
import { actions as bookingCalendarActions } from 'modules/bookingCalendar/actions';

import styles from './PracticeBookingSection.scss';

const defaultState = {
  speciality: '',
  selectedSpecialists: [],
  filteredSpecialists: [],
  availableReasons: [],
  uniqueActiveReasons: [],
  selectedReason: { id: '' },
  uniqueSortedTimeSlots: [],
  firstTimeSlot: '',
};

class PracticeBookingSection extends React.Component {
  constructor(props) {
    super(props);
    const specialities = this.getOrderedUniqueSpecialities(this.props.practice);

    this.state = {
      ...defaultState,
      specialities,
    };
  }

  componentDidMount() {
    const { specialities } = this.state;
    // automatically select first speciality if only one is available
    if (specialities.length === 1) {
      this.setSpeciality(specialities[0], this.props.practice);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setTimeSlots(nextProps.availableTimeSlots);

    const { selectedSpecialists } = this.state;

    if (this.props.practice.id !== nextProps.practice.id) {
      const specialities = this.getOrderedUniqueSpecialities(nextProps.practice);

      this.setState({ ...defaultState, specialities });

      // automatically select first speciality if only one is available
      if (specialities.length === 1) {
        this.setSpeciality(specialities[0], nextProps.practice);
      }
    } else if (selectedSpecialists && this.props.availableReasons !== nextProps.availableReasons) {
      const { availableReasons } = nextProps;

      // get reasons available for selected specialists
      const activeReasons = {};
      selectedSpecialists.forEach(specialist => {
        const calendarId = specialist.specialistPractice.clicrdvCalendarId;
        if (availableReasons[calendarId]) {
          activeReasons[calendarId] = availableReasons[calendarId];
        }
      });
      // flat reasons array
      const flatBookingReasons = Object.values(activeReasons).reduce(
        (accumulate, current) => accumulate.concat(current),
        []
      );
      const uniqueActiveReasons = uniqBy(flatBookingReasons, 'id');

      this.setState({ uniqueActiveReasons });
      // load timeslots immediately if there is only one
      if (uniqueActiveReasons.length === 1) {
        this.setReason(uniqueActiveReasons[0], availableReasons);
      }
    }
  }

  getSpecialistsCalendarId = (selectedSpecialists = []) =>
    selectedSpecialists
      .filter(item => item && item.specialistPractice && item.specialistPractice.clicrdvCalendarId)
      .map(item => item.specialistPractice.clicrdvCalendarId);

  getOrderedUniqueSpecialities = practice => {
    const uniqueSpecialities = uniqBy(practice.specialists, 'specialization').map(item => item.specialization);
    const orderedUniqueSpecialities = orderBy(uniqueSpecialities);

    return orderedUniqueSpecialities;
  };

  getUniqueSortedTimestlots = availableTimeSlots => {
    const specialistsCalendarIds = this.getSpecialistsCalendarId(this.state.selectedSpecialists);

    let nonUniqueTimeSlots = [];
    specialistsCalendarIds.forEach(calendarId => {
      const firstTimeSlot = availableTimeSlots[calendarId] ? availableTimeSlots[calendarId].firstSlot : '';
      const timeSlotsPerId = availableTimeSlots[calendarId] ? availableTimeSlots[calendarId].availabilities : [];
      nonUniqueTimeSlots = nonUniqueTimeSlots.concat(
        timeSlotsPerId.map(date => ({
          date,
          clicrdvCalendarId: calendarId,
          firstTimeSlot,
        }))
      );
    });
    const uniqueTimeSlots = {};
    nonUniqueTimeSlots.forEach(slot => {
      uniqueTimeSlots[slot.date] = slot;
    });
    const unsortedTimeSlots = Object.values(uniqueTimeSlots);

    // sorting
    return unsortedTimeSlots.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  };

  setTimeSlots(availableTimeSlots) {
    const { selectedSpecialists, selectedReason } = this.state;
    // gets timeslots from chosen specialist
    if (selectedSpecialists.length === 0 || Object.keys(availableTimeSlots).length === 0 || !selectedReason.id) {
      this.setState({
        uniqueSortedTimeSlots: [],
      });

      return;
    }

    const uniqueSortedTimeSlots = this.getUniqueSortedTimestlots(availableTimeSlots);
    const firstSlot = uniqueSortedTimeSlots[0] ? uniqueSortedTimeSlots[0].firstTimeSlot : '';
    this.setState({
      uniqueSortedTimeSlots,
      firstTimeSlot: firstSlot,
    });
  }

  setReason(selectedReason, availableReasons) {
    const reasonId = selectedReason.id;
    this.setState({ selectedReason }, () => {
      if (reasonId) {
        const { apiType, clicrdvGroupId } = this.props.practice;
        const { selectedSpecialists } = this.state;
        const calendarIds = [];
        selectedSpecialists.forEach(specialist => {
          const specialistAvailableReasons = availableReasons[specialist.specialistPractice.clicrdvCalendarId];
          if (specialistAvailableReasons) {
            specialistAvailableReasons.forEach(availableReason => {
              if (availableReason.id === reasonId) {
                calendarIds.push(specialist.specialistPractice.clicrdvCalendarId);
              }
            });
          }
        });

        this.props.dispatch(
          bookingCalendarActions.fetchAvailableTimeSlots(
            apiType,
            clicrdvGroupId,
            calendarIds,
            reasonId,
            false,
            clicrdvGroupId
          )
        );
      } else {
        this.setState({
          uniqueSortedTimeSlots: [],
        });
      }
    });
  }

  setSpeciality(speciality, practice) {
    const filteredSpecialists = speciality
      ? practice.specialists.filter(item => item.specialization === speciality)
      : [];

    this.setState({
      ...defaultState,
      speciality,
      filteredSpecialists,
    });

    this.setSpecialists(filteredSpecialists);
  }

  setSpecialists(selectedSpecialists) {
    this.setState({
      selectedSpecialists,
      selectedReason: { id: '' },
      firstTimeSlot: '',
      uniqueSortedTimeSlots: [],
    });

    this.fetchBookingReasons(selectedSpecialists);
  }

  handleSpecialityChange = e => {
    this.setSpeciality(e.target.value, this.props.practice);
  };

  handleSpecialistChange = e => {
    const {
      target: { value },
    } = e;
    const { filteredSpecialists } = this.state;
    const {
      practice: { specialists },
    } = this.props;

    // searching of the specialist via chosen 'any' option or selected specialist
    const selectedSpecialists =
      value === 'any' ? filteredSpecialists : [specialists.find(item => item.id === value * 1)];

    this.setSpecialists(selectedSpecialists);
  };

  handleReasonChange = e => {
    const selectedReason = this.state.uniqueActiveReasons.find(item => item.id === e.target.value * 1) || {};

    this.setReason(selectedReason, this.props.availableReasons);
  };

  /**
   * This method gets the reason list from list of the specialist
   *
   * @param {*} selectedSpecialists
   */
  fetchBookingReasons(selectedSpecialists) {
    const { apiType, clicrdvGroupId } = this.props.practice;
    const clicrdvCalendarIds = this.getSpecialistsCalendarId(selectedSpecialists);

    this.props.dispatch(
      bookingCalendarActions.fetchAvailableBookingReasonList(
        apiType,
        clicrdvGroupId,
        clicrdvCalendarIds,
        clicrdvGroupId
      )
    );
  }

  render() {
    const { practice } = this.props;
    const {
      specialities,
      speciality,
      selectedSpecialists,
      selectedReason,
      uniqueActiveReasons,
      filteredSpecialists,
    } = this.state;

    let defaultText;
    let hideEnquireButton = false;
    if (!speciality) {
      defaultText = <Trans>Please choose a speciality.</Trans>;
      hideEnquireButton = true;
    } else if (uniqueActiveReasons.length > 1 && !selectedReason.id) {
      defaultText = <Trans>Please choose a consultation type.</Trans>;
      hideEnquireButton = true;
    }

    return (
      <I18n>
        {({ i18n }) => (
          <div className={styles.bookingSection}>
            <div className={styles.bookingContent}>
              {practice.hasExternalBooking ? (
                <ExternalLink externalLink={practice.website} />
              ) : (
                <div>
                  <BookingSectionTitle />
                  <div className="mt-25">
                    <ul className={styles.steps}>
                      <li className={classNames(styles.checked, 'pb-25')}>
                        <div>
                          <div className={styles.check} />
                          <Trans>Place of consultation</Trans>
                          <div className="is-size-5-5 has-text-weight-semibold mt-15">{practice.name}</div>
                          <small id="jest-place">
                            {practice.address}, {practice.town} {practice.postCode}
                          </small>
                        </div>
                      </li>

                      <li className={classNames(speciality && styles.checked, 'pb-15')}>
                        {speciality ? (
                          <div className={styles.check} />
                        ) : (
                          <div className={`has-background-info ${styles.no}`}>2</div>
                        )}
                        <Trans>Choose a speciality</Trans>
                        <div className="select is-fullwidth mt-20 mb-15" id="jest-speciality">
                          <select className={styles.select} value={speciality} onChange={this.handleSpecialityChange}>
                            <option disabled value="">
                              {i18n._(t`Select`)}
                            </option>
                            {specialities.map(item => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </li>

                      {speciality && (
                        <li className={classNames(uniqueActiveReasons.length > 0 && styles.checked, 'pb-15')}>
                          {uniqueActiveReasons.length > 0 ? (
                            <div className={styles.check} />
                          ) : (
                            <div className={`has-background-info ${styles.no}`}>3</div>
                          )}
                          <Trans>Choose a specialist</Trans>
                          <div className="select is-fullwidth mt-20 mb-15" id="jest-specialist">
                            <select
                              className={styles.select}
                              value={selectedSpecialists.length === 1 && selectedSpecialists[0].id}
                              onChange={this.handleSpecialistChange}
                            >
                              <option value="any">{i18n._(t`Any`)}</option>
                              {filteredSpecialists.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.firstName} {item.lastName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </li>
                      )}

                      {selectedSpecialists &&
                        uniqueActiveReasons.length > 0 && (
                          <li className={classNames(selectedReason.id && styles.checked, 'pb-15')}>
                            {selectedReason.id ? (
                              <div className={styles.check} />
                            ) : (
                              <div className={`has-background-info ${styles.no}`}>4</div>
                            )}
                            <Trans>Choose a consultation type</Trans>
                            <div className="select is-fullwidth mt-20 mb-15" id="jest-reason">
                              <select
                                className={styles.select}
                                value={selectedReason.id}
                                onChange={this.handleReasonChange}
                              >
                                <option disabled value="">
                                  {i18n._(t`Select`)}
                                </option>
                                {uniqueActiveReasons.map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.publicname}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </li>
                        )}
                      {selectedReason.id && (
                        <li className="mb-20">
                          <div className={`has-background-info ${styles.no}`}>
                            {uniqueActiveReasons.length > 0 ? 5 : 4}
                          </div>
                          <Trans>Choose your slot</Trans>
                        </li>
                      )}
                    </ul>

                    <div className={classNames(defaultText && styles.hasSlots, styles.calendarContainer)}>
                      <BookingCalendarMultiSpec
                        spinnerId={practice.clicrdvGroupId}
                        practice={practice}
                        specialists={selectedSpecialists || [{ id: 0 }]}
                        timeslots={this.state.uniqueSortedTimeSlots}
                        firstTimeSlot={this.state.firstTimeSlot}
                        defaultText={defaultText}
                        interventionId={selectedReason.id ? selectedReason.id : undefined}
                        interventionName={selectedReason.publicname}
                        hideEnquireButton={hideEnquireButton}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </I18n>
    );
  }
}

PracticeBookingSection.propTypes = {
  availableTimeSlots: PropTypes.shape({}),
  availableReasons: PropTypes.shape({}),
  practice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    address: PropTypes.string,
    postCode: PropTypes.string,
    town: PropTypes.string,
    apiType: PropTypes.string,
    clicrdvGroupId: PropTypes.number,
    clicrdvCalendarId: PropTypes.number,
    specialists: PropTypes.arrayOf(PropTypes.shape({})),
    hasExternalBooking: PropTypes.bool.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default PracticeBookingSection;
