import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { actions } from './actions';

import PracticeView from './PracticeView';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
    },
    dispatch
  );

function mapStateToProps(appState) {
  return {
    ...appState.practiceView,
    userId: appState.user.userId,
    isAdminRole: appState.user.isAdminRole,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PracticeView);
