import React from 'react';
import PropTypes from 'prop-types';
import { groupBy, orderBy } from 'lodash';

import SpecialistPicture from 'components/specialist/specialistPicture';
import ProfileLink from 'components/link/ProfileLink';
import SearchLink from 'components/link/SearchLink';
import styles from './Team.scss';

const Team = ({ specialists, town }) => {
  let grouped;
  grouped = groupBy(specialists, item => item.specialization);
  grouped = orderBy(grouped, item => item[0].specialization, 'asc');

  return (
    <div>
      {grouped.map(group => (
        <div key={group[0].specialization} className="columns is-multiline is-mobile">
          <div className="column is-12">
            <SearchLink
              className="is-6 is-uppercase has-text-weight-semibold has-text-primary"
              town={town}
              speciality={group[0].specialization}
            >
              {group[0].specialization}
            </SearchLink>
          </div>

          {group.map(specialist => (
            <div key={specialist.id} className={`column is-4 has-text-centered ${styles.user}`}>
              <ProfileLink
                profileType="specialist"
                town={town}
                speciality={specialist.specialization}
                slug={specialist.slug}
              >
                <SpecialistPicture specialistId={specialist.id} profilePicture={specialist.profilePicture} />
                <div className="is-size-7 has-text-black">
                  {specialist.firstName} <span className="is-uppercase">{specialist.lastName}</span>
                </div>
              </ProfileLink>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

Team.propTypes = {
  specialists: PropTypes.arrayOf(
    PropTypes.shape({
      town: PropTypes.string,
    })
  ).isRequired,
  town: PropTypes.string,
};

export default Team;
