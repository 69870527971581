import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';

import { modalActions } from 'modules/modal/actions';
import { actions } from 'modules/practice/actions';
import SearchLink from 'components/link/SearchLink';

import styles from './PracticeHeader.scss';

const PracticeHeader = props => {
  const { dispatch, name, specialization, id, logo, town, allowEdit } = props;
  return (
    <div className={classNames(styles.headerWrapper, 'row-full')}>
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <div className={classNames('mr-30 ml-20 mt-5', styles.avatar)}>
              <div className={styles.avatarImage}>
                {(logo || allowEdit) && (
                  <React.Fragment>
                    {logo ? (
                      <img src={`/images/profile/Id${props.id}-ConvertPic.png`} alt={`${name} logo`} itemProp="image" />
                    ) : (
                      <div className={styles.noPicture}>
                        <i className="fa fa-cube" /> <br />
                        <Trans>Upload Logo</Trans>
                      </div>
                    )}
                  </React.Fragment>
                )}
                {allowEdit && (
                  <div
                    className={styles.btnUploadPicture}
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      dispatch(
                        modalActions.showPictureUploadModal(
                          id,
                          logo ? `/images/profile/Id${props.id}-ConvertPic.png` : '',
                          actions.updatePracticePicture
                        )
                      )
                    }
                  >
                    <i className="fa fa-cloud-upload" />
                  </div>
                )}
              </div>
            </div>

            <div className={styles.rtc}>
              <h1
                className={classNames(
                  'is-size-3 is-size-4-mobile has-text-weight-semibold has-text-primary',
                  styles.practiceName
                )}
              >
                {name}
              </h1>
              <h2 className="is-size-6 mb-15 has-text-weight-semibold">
                {specialization ? (
                  <SearchLink
                    itemProp="medicalSpecialty"
                    town={town}
                    speciality={specialization}
                    className="has-text-grey-dark"
                  >
                    {specialization}
                  </SearchLink>
                ) : (
                  'Medical practice'
                )}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PracticeHeader.defaultProps = {
  specialization: '',
};

PracticeHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  specialization: PropTypes.string,
  id: PropTypes.number.isRequired,
  logo: PropTypes.bool.isRequired,
  town: PropTypes.string.isRequired,
  allowEdit: PropTypes.bool,
};

export default connect()(PracticeHeader);
