import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';

import config from 'config';

import styles from './ExternalLink.scss';

const ExternalLink = ({ externalLink }) => (
  <div className={classNames('has-magic-gradient', styles.bookingLink)}>
    <a href={`${externalLink}?utm_source=${config.name}`}>
      <div className="is-size-4 has-text-white has-text-weight-semibold">
        <Trans>Book an appointment online</Trans>
      </div>
      <div className="has-text-white">
        <Trans>Click here!</Trans>
      </div>
    </a>
  </div>
);

ExternalLink.propTypes = {
  externalLink: PropTypes.string.isRequired,
};

export default ExternalLink;
