import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Route } from 'react-router-dom';

import config from 'config';
import * as practiceConstants from 'modules/specialist/constants';
import UniversalComponent from 'utils/universalComponent';
import About from 'components/profile/about';
import DescriptionCard from 'components/profile/descriptionCard';
import Location from 'components/profile/practice/location';
import Contacts from 'components/profile/practice/contacts';
import BookingSection from './bookingSection';
import PracticeHeader from './header';
import PaymentMethods from './paymentMethods';
import Team from './team';

class PracticeView extends Component {
  constructor(props) {
    super(props);

    if (props && props.match.params) {
      props.fetchPractice(props.match.params.slug);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.slug !== nextProps.match.params.slug) {
      this.props.fetchPractice(nextProps.match.params.slug);
    }
  }

  render() {
    const { practice, updatePracticeAbout, isAdminRole } = this.props;

    if (!practice) {
      return <Route render={() => <UniversalComponent page="notFoundView" />} />;
    }

    // first render can happen, before the practice is loaded
    if (!Object.keys(practice).length) {
      return null;
    }

    const allowEdit = !!this.props.userId && (practice.userId === this.props.userId || isAdminRole);
    const fullPractice = practice.level > practiceConstants.DISABLED;

    let title = `${practice.name}, `;
    title += practice.specialization ? `${practice.specialization}, ` : '';
    title += `${practice.borough}, ${practice.town} - ${config.name}`;

    return (
      <div className="main-wrapper">
        <div className="container">
          <Helmet
            title={title}
            script={[
              {
                src: '//cdnjs.cloudflare.com/ajax/libs/tinymce/4.8.5/tinymce.min.js',
              },
            ]}
          />

          <PracticeHeader {...practice} allowEdit={allowEdit} />

          <BookingSection practice={practice} />

          <div className="columns">
            <div className="column is-7">
              {(allowEdit || (fullPractice && practice.profile)) && (
                <DescriptionCard titleIconUrl="/images/icons/presentation.svg" title={<Trans>Practice info</Trans>}>
                  <About
                    allowEdit={allowEdit}
                    updateAbout={updatePracticeAbout}
                    text={practice.profile}
                    id={practice.id}
                    showLess
                  />
                </DescriptionCard>
              )}

              <DescriptionCard titleIconUrl="/images/icons/map.svg" title={<Trans>Map and access</Trans>}>
                <Location practice={practice} allowEdit={allowEdit} />
              </DescriptionCard>

              {practice.phoneNumber && (
                <DescriptionCard titleIconUrl="/images/icons/clock.svg" title={<Trans>Contacts</Trans>}>
                  <Contacts
                    website={practice.website}
                    phoneNumber={practice.phoneNumber}
                    profileType="practice"
                    profileTypeId={practice.id}
                    profileTypeName={practice.name}
                  />
                </DescriptionCard>
              )}

              {(allowEdit || (practice.paymentMethods && practice.paymentMethods.length > 0)) && (
                <DescriptionCard titleIconUrl="/images/icons/price.svg" title={<Trans>Payment methods</Trans>}>
                  <PaymentMethods
                    allowEdit={allowEdit}
                    practiceId={practice.id}
                    paymentMethods={practice.paymentMethods}
                  />
                </DescriptionCard>
              )}

              {practice.specialists &&
                practice.specialists.length > 0 && (
                  <DescriptionCard titleIconUrl="/images/icons/team-icon.png" title={<Trans>Team</Trans>}>
                    <Team specialists={practice.specialists} town={practice.town} />
                  </DescriptionCard>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PracticeView.defaultProps = {
  userId: 0,
};

PracticeView.propTypes = {
  practice: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    level: PropTypes.number,
    userId: PropTypes.number,
    paymentMethods: PropTypes.array,
    profile: PropTypes.string,
    phoneNumber: PropTypes.string,
    specialization: PropTypes.string,
    specialists: PropTypes.array,
  }).isRequired,
  fetchPractice: PropTypes.func.isRequired,
  updatePracticeAbout: PropTypes.func.isRequired,
  userId: PropTypes.number,
  isAdminRole: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
  }).isRequired,
  i18n: PropTypes.shape({
    _: PropTypes.func.isRequired,
  }),
};

export default PracticeView;
