import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { modalActions } from 'modules/modal/actions';

const PaymentMethods = props => {
  const { dispatch, allowEdit, practiceId, paymentMethods } = props;

  return allowEdit || (paymentMethods && paymentMethods.length > 0) ? (
    <div>
      <ul>
        {paymentMethods.map(paymentMethod => (
          <li key={paymentMethod.id}>{paymentMethod.name}</li>
        ))}
      </ul>

      {allowEdit && (
        <div className="has-text-right mt-10">
          <span
            role="button"
            tabIndex={0}
            onClick={() => dispatch(modalActions.showPaymentMethodsModal(practiceId, paymentMethods))}
          >
            <img src="/images/pencil.png" alt="pencil" className="ml-10" />
          </span>
        </div>
      )}
    </div>
  ) : null;
};

PaymentMethods.defaultProps = {
  allowEdit: false,
};

PaymentMethods.propTypes = {
  dispatch: PropTypes.func.isRequired,
  allowEdit: PropTypes.bool,
  practiceId: PropTypes.number.isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default connect()(PaymentMethods);
